import '../App.css';

function Register() {
    return (
        <div className="Register">
            <p>Hey there, this is the register page.</p>
        </div>
    );
}

export default Register;
