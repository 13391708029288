import '../App.css';

import NavbarMain from "../Components/Navbar";
import Footer from "../Components/Footer";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import { useRef, useEffect } from "react";
import axios from "axios";
import login from "./Login";

import Loading_gif from '../Assets/reading_email_1.gif';
import EmptyInboxImg from '../Assets/empty_inbox.png';

function Home() {

    const navigate = useNavigate();
    const [isRandomButtonDisabled, setIsRandomButtonDisabled] = useState(false);
    const [isGettingEmailsDisabled, setIsGettingEMailsDisabled] = useState(false);

    let api_endpoint = "http://localhost:5000/api/v1/";

    async function getRandomEmail(event){
        event.preventDefault();
        try{
            const random_email_holder_parag = document.getElementById('random_mailbox_holder');
            random_email_holder_parag.innerHTML = ``;
            setIsRandomButtonDisabled(true);
            const response = await axios.get(api_endpoint.concat('get_random_mailbox'));
            const data = response.data;
            let random_mailbox = data['random_mailbox'][0];
            random_email_holder_parag.innerHTML=`<span class="random_mailbox_span">${random_mailbox}</span>`;
        } catch (error) {
            alert('Requesting random mailbox has failed, please try again. more details: ' + error);
        } finally {
             setIsRandomButtonDisabled(false);
        }
    }

    const fetchDomainsList = async () => {
        try {
            const active_domains_list = await axios.get(api_endpoint.concat('get_active_domain'));
            console.log(active_domains_list.data['domains_list']);
            const domain_list_dropdown = document.getElementById('domains_select');
            // in here we will append these domains to the dropdown.
            let domains_list = active_domains_list.data['domains_list'];
            let domain_list_len = domains_list.length;
            let i = 0;
            while (i < domain_list_len) {
                let option_holder = document.createElement('option');
                option_holder.setAttribute('value', domains_list[i]);
                option_holder.textContent = domains_list[i];
                // adding the option to the list
                domain_list_dropdown.appendChild(option_holder);
                i = i+1;
            }
        } catch (error) {
            alert('Requesting active domains list has failed, please refresh the page. more details: ' + error);
        }


    }
    useEffect(() => {
        setIsGettingEMailsDisabled(true);
        fetchDomainsList().then(r => {});
    }, []);

    async function getMailboxEmails(){

        const sel_email = document.getElementById('selected_email');
        let selected_email_value = sel_email.textContent;
        const elems_list = selected_email_value.split('@');

        let request_object = {
            sel_username: elems_list[0],
            sel_domain: elems_list[1]
        };
        //'https://cdn.pixabay.com/animation/2022/07/29/03/42/03-42-05-37_512.gif'
        try {
            setIsGettingEMailsDisabled(true);
            const mail_box_div = document.getElementById('emails_holder_div_id');
            mail_box_div.innerHTML = `<div class='loading_email_progress_div'><p class="lading_emails_parag">
                                        Loading your emails...</p>
                                        <img class="loading_inbox_img" alt="loading inbox" src=${Loading_gif}>
                                        </div>`;
            const response = await axios.post(api_endpoint.concat('get_domain_mails'), request_object);
            const data = response.data;
            //console.log(data);
            if (data['messages_num'] === 0) {
                mail_box_div.innerHTML = `<div class='no_emails_div'>
                                            <img draggable='false' class='no_emails_img' alt='image to indicate emptiness' src=${EmptyInboxImg}/>
                                        </div>`;
            } else{
                let emails_list = data['res_data'];
                console.log('number of messages: ' + emails_list.length);
                mail_box_div.innerHTML = ``;
                let i = 0;
                while (i < emails_list.length) {
                    const email_elem_div = document.createElement('div');
                    email_elem_div.innerHTML = `
                    <a href="/email_explorer?email_id=${emails_list[i].id}&login=${request_object.sel_username}&domain=${request_object.sel_domain}" class="email_elem_link"><div class='email_list_element'>
                         <div class='email_subject'>
                              <span class="email_elem_subject">${emails_list[i].subject}</span>
                              <span>${emails_list[i].date}</span>
                         </div>
                         <div class='email_sender'>
                              <span class='from_txt'>Sender: </span><span>${emails_list[i].from}</span>
                         </div>

                    </div></a>`;

                    mail_box_div.append(email_elem_div);
                    i = i + 1;
                }
            }

        } catch (error) {
            alert(error);
        } finally {
            setIsGettingEMailsDisabled(false);
        }


    }

    async function readSelectedMessage(){

    }
    // proceed buttons, done in this way to handle the onClick property
    const randomMailboxProceed = () => {
        setIsGettingEMailsDisabled(false);
        let random_mailbox_value = document.getElementById('random_mailbox_holder');
        console.log('proceeding with: ' + random_mailbox_value.textContent);
        const mailbox_conf_parag = document.getElementById('selected_mailbox_confirmation');
        mailbox_conf_parag.innerHTML = `<span id="selected_email" class="selected_mailbox_span">${random_mailbox_value.textContent}</span>`;
    }
    const customMailBoxProceed = (event) => {
        setIsGettingEMailsDisabled(false);
        event.preventDefault();
        let custom_username = document.getElementById('custom_username').value;

        let selected_domain_element = document.getElementById('domains_select');
        const selected_option = selected_domain_element.options[selected_domain_element.selectedIndex].value;

        // constructing the email.
        let custom_email_full = custom_username.concat('@').concat(selected_option);

        // save the email value to the email confirmation
        const mailbox_conf_parag = document.getElementById('selected_mailbox_confirmation');
        mailbox_conf_parag.innerHTML = `<span id="selected_email" class="selected_mailbox_span">${custom_email_full}</span>`;

    }

    // test google ads example using useEffect
    useEffect( () => {
        if (window.googletag) {
            // Initialize ads directely
            window.googletag.cmd.push(() => {
                // Define an ad slot for div with id "banner-ad"
                window.googletag
                    .defineSlot("/6355419/Travel/Europe/France/Paris", [300, 250], "banner-ad")
                    .addService(window.googletag.pubads());
               
                // Enable the PubAdsService.
                window.googletag.enableServices();

                // Request and render an ad for the "banner-ad" slot
                window.googletag.display("banner-ad");
               
            });
        } else {
            // If googletag is not loaded, load it dynamically
            const script = document.createElement('script');
            script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
            script.async = true;
            script.onload = () => {
                // Once the script is loaded, initialize the ad
                window.googletag = window.googletag || { cmd: [] };
                window.googletag.cmd.push(() => {
                    window.googletag
                        .defineSlot("/6355419/Travel/Europe/France/Paris", [300, 250], "banner-ad")
                        .addService(window.googletag.pubads());
                    window.googletag.enableServices();
                    window.googletag.display("banner-ad");
                
                });
            };

            // Append the script to the document head
            document.head.appendChild(script);
        }
    }, []);


  return (
      <>
      {<NavbarMain />}
            <div className={'main_container'}>
                <div className={'container_main_holding'}>
                    <section className={'main-hero-section'}>
                        <div className={'hero-ads-container'}>will contain ads</div>
                        <div className={'hero-temp-mail-container '}>
                            <div className={'temp-mailbox'}>
                                <div className={''}>
                                    <div className={'temp-email-up'}>
                                        <h2>Temporary email address</h2>
                                        <div>
                                            <div className={'input-box-col'}>
                                                <div className={'input-wrap'}>
                                                    <div className={'random-loading-div'}><p className={'random-email-text-up'}>Loading...</p></div>
                                                    <button className={'temp-box-refresh-btn'}>

                                                           <img className={'refresh_img'} style={{width: '20px',
                                                               height: '30px'}} src={'https://www.svgrepo.com/show/18270/refresh.svg'} />

                                                    </button>

                                                </div>
                                                <div>

                                                </div>
                                                <button className={'temp-box-apply-btn'}>
                                                    <img className={'apply_img'} style={{
                                                        width: '40px',
                                                        height: '40px'
                                                    }} src={'https://www.svgrepo.com/show/521272/add-category.svg'}/>

                                                </button>
                                            </div>


                                        </div>

                                    </div>


                                    <div>
                                    <h2>Or Create a custom email address</h2>
                                        <div className={'custom-mail-div-up'}>

                                            <input minLength={6} maxLength={12  } className={'custom-domain-input-sd40'} type={'text'}/>

                                            <select className={'input-ser45'}>
                                                <option className={'email-domain-option'}>email.com</option>
                                                <option className={'email-domain-option'}>exampmail.com</option>
                                                <option className={'email-domain-option'}>email.com</option>
                                                <option className={'email-domain-option'}>exampmail.com</option>
                                                <option className={'email-domain-option'}>email.com</option>
                                                <option className={'email-domain-option'}>exampmail.com</option>
                                            </select>
                                            <button className={'temp-box-apply-btn'}>
                                                <img className={'apply_img'} style={{
                                                    width: '40px',
                                                    height: '40px'
                                                }} src={'https://www.svgrepo.com/show/521272/add-category.svg'}/>

                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className={'temp-mail-under-text'}>
                                <p>Forget about spam, advertising mailings, hacking and attacking robots.
                                Keep your real mailbox clean and secure. Temp Mail provides temporary, secure,
                                anonymous, free, disposable email address.</p></div>
                        </div>

                        <div className={'hero-ads-container'}>will contain ads</div>
                    </section>


                    <section className={'email-confirm-sd30-section'}>
                        <div className={'email-confirm-parent-div'}>
                            <div className={'email-confirm-child-div'}>
                                <div className={'system-wide-sel-email'}>
                                    <div className={'confirmed-email-container'}>
                                        example-mail-template
                                    </div>
                                    <button className={'email-copy-btn'}>
                                        <img style={{
                                            width: '40px',
                                            height: '40px'
                                        }} src={'https://www.svgrepo.com/show/526961/copy.svg'}/>
                                    </button>
                                </div>

                            </div>

                        </div>
                    </section>


                    <main>
                        <div className={'container'}>
                            <div className={'row'}>

                                <div className={'tm-content'}>

                                    <div className={'ts-inbox-section-ad-placement'}>
                                    <div>will contain ads</div>
                                        <div className={'inbox-wrap-main'}>
                                            <div className={'inbox-area-mailing'}>
                                                <div className={'inbox-header'}>
                                                    <div className={'col-h1'}>Sender</div>
                                                    <div className={'col-h1'}>Subject</div>
                                                    <div className={'small-h'}>View</div>

                                                </div>
                                                <div className={'inbox-empty'}>
                                                    {/* this will be shown when the email system is empty*/}
                                                    {/*
                                                    <div className={'inbox-empty-ms-g'}>
                                                        <img alt={''} className={'no-emails-placeholder-img'}
                                                             src={EmptyInboxImg}/>
                                                        <div className={'no-emails-loading-div'}>
                                                            <img alt={''} src={Loading_gif}
                                                                 className={'loading-gif-no-emails'}/>
                                                            <p className={'inbox-no-main-desc'}>Waiting for incoming
                                                                emails</p>
                                                        </div>

                                                    </div> */}

                                                    {/*this will be used when emails were loaded from the server*/}
                                                    <div className={'inbox-active-ms-g'}>
                                                        <ul className={'inbox-details-ul'}>
                                                            <li className={'inbox-details-li'}>
                                                                <div className={'col-box'}>
                                                                    <a className={'view-link'}>
                                                                        <span className={'bullet-icon'}></span>
                                                                        <span
                                                                            className={'inbox-sender-name'}>MEGA</span>
                                                                        <span
                                                                            className={'inbox-sender-ellipsis'}>welcome@mega.nz</span>
                                                                    </a>
                                                                </div>
                                                                <div className={'inbox-subject-xs-m'}>
                                                                    <span className={'inbox-subject'}>MEGA email verification required</span>
                                                                </div>
                                                                <div className={'col-box'}>
                                                                    <div className={'attachment'}>
                                                                        <a className={'view-link-attachment'}>
                                                                            <svg style={{
                                                                                width: '24px',
                                                                                height: '24px',
                                                                                fill: '#8C92A5'
                                                                            }}
                                                                                 viewBox="0 0 24 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd"
                                                                                      clipRule="evenodd"
                                                                                      d="M9.18147 19C7.89265 18.9946 6.7333 18.2153 6.24166 17.0239C5.75003 15.8325 6.0224 14.4624 6.93233 13.5496L12.3571 8.12485C13.1152 7.4011 14.3083 7.4011 15.0663 8.12485C15.8138 8.87325 15.8138 10.0857 15.0663 10.834L12.8044 13.096C12.5509 13.3131 12.173 13.2985 11.937 13.0625C11.7009 12.8265 11.6863 12.4486 11.9034 12.195L14.1654 9.93311C14.4131 9.68389 14.4131 9.2814 14.1654 9.03218C13.9162 8.78444 13.5137 8.78444 13.2644 9.03218L7.83965 14.457C7.36195 14.9439 7.17901 15.648 7.35927 16.3059C7.53953 16.9638 8.05584 17.4763 8.71503 17.6518C9.37143 17.8217 10.0687 17.6321 10.5488 17.1534L15.9736 11.7286C17.1844 10.475 17.1671 8.4824 15.9347 7.25005C14.7024 6.01771 12.7098 6.00039 11.4562 7.21114L7.39238 11.2749C7.13887 11.492 6.76096 11.4774 6.52495 11.2414C6.28894 11.0054 6.27434 10.6275 6.49144 10.374L10.5552 6.3102C11.6852 5.18022 13.3322 4.73891 14.8758 5.15251C16.4194 5.56612 17.625 6.77179 18.0386 8.31537C18.4522 9.85896 18.0109 11.5059 16.881 12.6359L11.4562 18.0607C10.8538 18.665 10.0347 19.0032 9.18147 19Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div className={'m-link-view'}>
                                                                        <a className={'m-link-view-a'}>
                                                                            <svg className="arrow-link-ico"
                                                                                 style={{width: '10px', height: '24px'}}
                                                                                 viewBox="0 0 10 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M3.33301 16.25L7.49967 11.25L3.33301 6.25"
                                                                                    stroke="#CDCDD8"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </li>
                                                            <li className={'inbox-details-li'}>
                                                                <div className={'col-box'}>
                                                                    <a className={'view-link'}>
                                                                        <span className={'bullet-icon'}></span>
                                                                        <span
                                                                            className={'inbox-sender-name'}>MEGA</span>
                                                                        <span
                                                                            className={'inbox-sender-ellipsis'}>welcome@mega.nz</span>
                                                                    </a>
                                                                </div>
                                                                <div className={'inbox-subject-xs-m'}>
                                                                    <span className={'inbox-subject'}>MEGA email verification required</span>
                                                                </div>
                                                                <div className={'col-box'}>
                                                                    <div className={'attachment'}>
                                                                        <a className={'view-link-attachment'}>
                                                                            <svg style={{
                                                                                width: '24px',
                                                                                height: '24px',
                                                                                fill: '#8C92A5'
                                                                            }}
                                                                                 viewBox="0 0 24 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd"
                                                                                      clipRule="evenodd"
                                                                                      d="M9.18147 19C7.89265 18.9946 6.7333 18.2153 6.24166 17.0239C5.75003 15.8325 6.0224 14.4624 6.93233 13.5496L12.3571 8.12485C13.1152 7.4011 14.3083 7.4011 15.0663 8.12485C15.8138 8.87325 15.8138 10.0857 15.0663 10.834L12.8044 13.096C12.5509 13.3131 12.173 13.2985 11.937 13.0625C11.7009 12.8265 11.6863 12.4486 11.9034 12.195L14.1654 9.93311C14.4131 9.68389 14.4131 9.2814 14.1654 9.03218C13.9162 8.78444 13.5137 8.78444 13.2644 9.03218L7.83965 14.457C7.36195 14.9439 7.17901 15.648 7.35927 16.3059C7.53953 16.9638 8.05584 17.4763 8.71503 17.6518C9.37143 17.8217 10.0687 17.6321 10.5488 17.1534L15.9736 11.7286C17.1844 10.475 17.1671 8.4824 15.9347 7.25005C14.7024 6.01771 12.7098 6.00039 11.4562 7.21114L7.39238 11.2749C7.13887 11.492 6.76096 11.4774 6.52495 11.2414C6.28894 11.0054 6.27434 10.6275 6.49144 10.374L10.5552 6.3102C11.6852 5.18022 13.3322 4.73891 14.8758 5.15251C16.4194 5.56612 17.625 6.77179 18.0386 8.31537C18.4522 9.85896 18.0109 11.5059 16.881 12.6359L11.4562 18.0607C10.8538 18.665 10.0347 19.0032 9.18147 19Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div className={'m-link-view'}>
                                                                        <a className={'m-link-view-a'}>
                                                                            <svg className="arrow-link-ico"
                                                                                 style={{width: '10px', height: '24px'}}
                                                                                 viewBox="0 0 10 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M3.33301 16.25L7.49967 11.25L3.33301 6.25"
                                                                                    stroke="#CDCDD8"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </li>
                                                            <li className={'inbox-details-li'}>
                                                                <div className={'col-box'}>
                                                                    <a className={'view-link'}>
                                                                        <span className={'bullet-icon'}></span>
                                                                        <span
                                                                            className={'inbox-sender-name'}>MEGA</span>
                                                                        <span
                                                                            className={'inbox-sender-ellipsis'}>welcome@mega.nz</span>
                                                                    </a>
                                                                </div>
                                                                <div className={'inbox-subject-xs-m'}>
                                                                    <span className={'inbox-subject'}>MEGA email verification required</span>
                                                                </div>
                                                                <div className={'col-box'}>
                                                                    <div className={'attachment'}>
                                                                        <a className={'view-link-attachment'}>
                                                                            <svg style={{
                                                                                width: '24px',
                                                                                height: '24px',
                                                                                fill: '#8C92A5'
                                                                            }}
                                                                                 viewBox="0 0 24 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd"
                                                                                      clipRule="evenodd"
                                                                                      d="M9.18147 19C7.89265 18.9946 6.7333 18.2153 6.24166 17.0239C5.75003 15.8325 6.0224 14.4624 6.93233 13.5496L12.3571 8.12485C13.1152 7.4011 14.3083 7.4011 15.0663 8.12485C15.8138 8.87325 15.8138 10.0857 15.0663 10.834L12.8044 13.096C12.5509 13.3131 12.173 13.2985 11.937 13.0625C11.7009 12.8265 11.6863 12.4486 11.9034 12.195L14.1654 9.93311C14.4131 9.68389 14.4131 9.2814 14.1654 9.03218C13.9162 8.78444 13.5137 8.78444 13.2644 9.03218L7.83965 14.457C7.36195 14.9439 7.17901 15.648 7.35927 16.3059C7.53953 16.9638 8.05584 17.4763 8.71503 17.6518C9.37143 17.8217 10.0687 17.6321 10.5488 17.1534L15.9736 11.7286C17.1844 10.475 17.1671 8.4824 15.9347 7.25005C14.7024 6.01771 12.7098 6.00039 11.4562 7.21114L7.39238 11.2749C7.13887 11.492 6.76096 11.4774 6.52495 11.2414C6.28894 11.0054 6.27434 10.6275 6.49144 10.374L10.5552 6.3102C11.6852 5.18022 13.3322 4.73891 14.8758 5.15251C16.4194 5.56612 17.625 6.77179 18.0386 8.31537C18.4522 9.85896 18.0109 11.5059 16.881 12.6359L11.4562 18.0607C10.8538 18.665 10.0347 19.0032 9.18147 19Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div className={'m-link-view'}>
                                                                        <a className={'m-link-view-a'}>
                                                                            <svg className="arrow-link-ico"
                                                                                 style={{width: '10px', height: '24px'}}
                                                                                 viewBox="0 0 10 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M3.33301 16.25L7.49967 11.25L3.33301 6.25"
                                                                                    stroke="#CDCDD8"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </li>
                                                            <li className={'inbox-details-li'}>
                                                                <div className={'col-box'}>
                                                                    <a className={'view-link'}>
                                                                        <span className={'bullet-icon'}></span>
                                                                        <span
                                                                            className={'inbox-sender-name'}>MEGA</span>
                                                                        <span
                                                                            className={'inbox-sender-ellipsis'}>welcome@mega.nz</span>
                                                                    </a>
                                                                </div>
                                                                <div className={'inbox-subject-xs-m'}>
                                                                    <span className={'inbox-subject'}>MEGA email verification required</span>
                                                                </div>
                                                                <div className={'col-box'}>
                                                                    <div className={'attachment'}>
                                                                        <a className={'view-link-attachment'}>
                                                                            <svg style={{
                                                                                width: '24px',
                                                                                height: '24px',
                                                                                fill: '#8C92A5'
                                                                            }}
                                                                                 viewBox="0 0 24 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd"
                                                                                      clipRule="evenodd"
                                                                                      d="M9.18147 19C7.89265 18.9946 6.7333 18.2153 6.24166 17.0239C5.75003 15.8325 6.0224 14.4624 6.93233 13.5496L12.3571 8.12485C13.1152 7.4011 14.3083 7.4011 15.0663 8.12485C15.8138 8.87325 15.8138 10.0857 15.0663 10.834L12.8044 13.096C12.5509 13.3131 12.173 13.2985 11.937 13.0625C11.7009 12.8265 11.6863 12.4486 11.9034 12.195L14.1654 9.93311C14.4131 9.68389 14.4131 9.2814 14.1654 9.03218C13.9162 8.78444 13.5137 8.78444 13.2644 9.03218L7.83965 14.457C7.36195 14.9439 7.17901 15.648 7.35927 16.3059C7.53953 16.9638 8.05584 17.4763 8.71503 17.6518C9.37143 17.8217 10.0687 17.6321 10.5488 17.1534L15.9736 11.7286C17.1844 10.475 17.1671 8.4824 15.9347 7.25005C14.7024 6.01771 12.7098 6.00039 11.4562 7.21114L7.39238 11.2749C7.13887 11.492 6.76096 11.4774 6.52495 11.2414C6.28894 11.0054 6.27434 10.6275 6.49144 10.374L10.5552 6.3102C11.6852 5.18022 13.3322 4.73891 14.8758 5.15251C16.4194 5.56612 17.625 6.77179 18.0386 8.31537C18.4522 9.85896 18.0109 11.5059 16.881 12.6359L11.4562 18.0607C10.8538 18.665 10.0347 19.0032 9.18147 19Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div className={'m-link-view'}>
                                                                        <a className={'m-link-view-a'}>
                                                                            <svg className="arrow-link-ico"
                                                                                 style={{width: '10px', height: '24px'}}
                                                                                 viewBox="0 0 10 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M3.33301 16.25L7.49967 11.25L3.33301 6.25"
                                                                                    stroke="#CDCDD8"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </li>
                                                            <li className={'inbox-details-li'}>
                                                                <div className={'col-box'}>
                                                                    <a className={'view-link'}>
                                                                        <span className={'bullet-icon'}></span>
                                                                        <span
                                                                            className={'inbox-sender-name'}>MEGA</span>
                                                                        <span
                                                                            className={'inbox-sender-ellipsis'}>welcome@mega.nz</span>
                                                                    </a>
                                                                </div>
                                                                <div className={'inbox-subject-xs-m'}>
                                                                    <span className={'inbox-subject'}>MEGA email verification required</span>
                                                                </div>
                                                                <div className={'col-box'}>
                                                                    <div className={'attachment'}>
                                                                        <a className={'view-link-attachment'}>
                                                                            <svg style={{
                                                                                width: '24px',
                                                                                height: '24px',
                                                                                fill: '#8C92A5'
                                                                            }}
                                                                                 viewBox="0 0 24 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd"
                                                                                      clipRule="evenodd"
                                                                                      d="M9.18147 19C7.89265 18.9946 6.7333 18.2153 6.24166 17.0239C5.75003 15.8325 6.0224 14.4624 6.93233 13.5496L12.3571 8.12485C13.1152 7.4011 14.3083 7.4011 15.0663 8.12485C15.8138 8.87325 15.8138 10.0857 15.0663 10.834L12.8044 13.096C12.5509 13.3131 12.173 13.2985 11.937 13.0625C11.7009 12.8265 11.6863 12.4486 11.9034 12.195L14.1654 9.93311C14.4131 9.68389 14.4131 9.2814 14.1654 9.03218C13.9162 8.78444 13.5137 8.78444 13.2644 9.03218L7.83965 14.457C7.36195 14.9439 7.17901 15.648 7.35927 16.3059C7.53953 16.9638 8.05584 17.4763 8.71503 17.6518C9.37143 17.8217 10.0687 17.6321 10.5488 17.1534L15.9736 11.7286C17.1844 10.475 17.1671 8.4824 15.9347 7.25005C14.7024 6.01771 12.7098 6.00039 11.4562 7.21114L7.39238 11.2749C7.13887 11.492 6.76096 11.4774 6.52495 11.2414C6.28894 11.0054 6.27434 10.6275 6.49144 10.374L10.5552 6.3102C11.6852 5.18022 13.3322 4.73891 14.8758 5.15251C16.4194 5.56612 17.625 6.77179 18.0386 8.31537C18.4522 9.85896 18.0109 11.5059 16.881 12.6359L11.4562 18.0607C10.8538 18.665 10.0347 19.0032 9.18147 19Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div className={'m-link-view'}>
                                                                        <a className={'m-link-view-a'}>
                                                                            <svg className="arrow-link-ico"
                                                                                 style={{width: '10px', height: '24px'}}
                                                                                 viewBox="0 0 10 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M3.33301 16.25L7.49967 11.25L3.33301 6.25"
                                                                                    stroke="#CDCDD8"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </li>

                                                        </ul>
                                                    </div>

                                                </div>
                                                {/** generating nothe div that will show when the emails are shown */}

                                            </div>


                                        </div>
                                        <div>will contain ads</div>
                                    </div>


                                    <div className={'mid-intro-text'}>
                                        <h1 className={'mid-intro-text-h1'}>What is Disposable Temporary E-mail?</h1>
                                        <p className={'mid-intro-text-p'}>
                                            <b>Disposable email</b>- is a free email service that allows to receive
                                            email at a
                                            temporary address that self-destructed after a certain time elapses. It is
                                            also
                                            known by names like : tempmail, 10minutemail, 10minmail, throwaway email,
                                            fake-mail
                                            , fake email generator, burner mail or trash-mail. Many forums, Wi-Fi
                                            owners,
                                            websites and blogs ask visitors to register before they can view content,
                                            post
                                            comments or download something. Temp-Mail - is most advanced throwaway email
                                            service that helps you avoid spam and stay safe.
                                        </p>


                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className={'section-popular-articles'}>
                            <div className={'popular-articles-ad'}><p>will contain ads</p></div>

                            <div className={'container'}>
                                <div className={'row'}>
                                    <div className={'col-xs-12'}>
                                        <div className={'p-header'}>
                                            <h3 className={'p-header-h3'}>Popular Articles</h3>
                                        </div>
                                        <ul className={'ul-popular-article-list'}>
                                            <li className={'li-col-sm'}>
                                                <div className={'li-inner-text-box'}>
                                                    <figure className={'popular-articles-figure'}>
                                                        <img alt={''}
                                                             src={'https://temp-mail.org/images/cache/blog/ghost/en_temporary-email-with-private-domains-guide-2021_124x124.webp'}
                                                             className={'figure-image'}/>
                                                    </figure>
                                                    <Link className={'inner-text-box-a'}
                                                          to={'/blog/temp-mail-use-cases'}>
                                                        Private domains. How to get your own Temporary Email (2021)
                                                    </Link>
                                                    <p className={'inner-text-box-p'}>
                                                        How to create temporary email on your own private domain and how
                                                        it helps you to
                                                        bypass common issues with registrations.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className={'li-col-sm'}>
                                                <div className={'li-inner-text-box'}>
                                                    <figure className={'popular-articles-figure'}>
                                                        <img alt={''}
                                                             src={'https://temp-mail.org/images/cache/blog/ghost/en_temporary-email-with-private-domains-guide-2021_124x124.webp'}
                                                             className={'figure-image'}/>
                                                    </figure>
                                                    <Link className={'inner-text-box-a'}
                                                          to={'/blog/temp-mail-use-cases'}>
                                                        Private domains. How to get your own Temporary Email (2021)
                                                    </Link>
                                                    <p className={'inner-text-box-p'}>
                                                        How to create temporary email on your own private domain and how
                                                        it helps you to
                                                        bypass common issues with registrations.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className={'li-col-sm'}>
                                                <div className={'li-inner-text-box'}>
                                                    <figure className={'popular-articles-figure'}>
                                                        <img alt={''}
                                                             src={'https://temp-mail.org/images/cache/blog/ghost/en_temporary-email-with-private-domains-guide-2021_124x124.webp'}
                                                             className={'figure-image'}/>
                                                    </figure>
                                                    <Link className={'inner-text-box-a'}
                                                          to={'/blog/temp-mail-use-cases'}>
                                                        Private domains. How to get your own Temporary Email (2021)
                                                    </Link>
                                                    <p className={'inner-text-box-p'}>
                                                        How to create temporary email on your own private domain and how
                                                        it helps you to
                                                        bypass common issues with registrations.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className={'li-col-sm'}>
                                                <div className={'li-inner-text-box'}>
                                                    <figure className={'popular-articles-figure'}>
                                                        <img alt={''}
                                                             src={'https://temp-mail.org/images/cache/blog/ghost/en_temporary-email-with-private-domains-guide-2021_124x124.webp'}
                                                             className={'figure-image'}/>
                                                    </figure>
                                                    <Link className={'inner-text-box-a'}
                                                          to={'/blog/temp-mail-use-cases'}>
                                                        Private domains. How to get your own Temporary Email (2021)
                                                    </Link>
                                                    <p className={'inner-text-box-p'}>
                                                        How to create temporary email on your own private domain and how
                                                        it helps you to
                                                        bypass common issues with registrations.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className={'li-col-sm'}>
                                                <div className={'li-inner-text-box'}>
                                                    <figure className={'popular-articles-figure'}>
                                                        <img alt={''}
                                                             src={'https://temp-mail.org/images/cache/blog/ghost/en_temporary-email-with-private-domains-guide-2021_124x124.webp'}
                                                             className={'figure-image'}/>
                                                    </figure>
                                                    <Link className={'inner-text-box-a'}
                                                          to={'/blog/temp-mail-use-cases'}>
                                                        Private domains. How to get your own Temporary Email (2021)
                                                    </Link>
                                                    <p className={'inner-text-box-p'}>
                                                        How to create temporary email on your own private domain and how
                                                        it helps you to
                                                        bypass common issues with registrations.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className={'li-col-sm'}>
                                                <div className={'li-inner-text-box'}>
                                                    <figure className={'popular-articles-figure'}>
                                                        <img alt={''}
                                                             src={'https://temp-mail.org/images/cache/blog/ghost/en_temporary-email-with-private-domains-guide-2021_124x124.webp'}
                                                             className={'figure-image'}/>
                                                    </figure>
                                                    <Link className={'inner-text-box-a'}
                                                          to={'/blog/temp-mail-use-cases'}>
                                                        Private domains. How to get your own Temporary Email (2021)
                                                    </Link>
                                                    <p className={'inner-text-box-p'}>
                                                        How to create temporary email on your own private domain and how
                                                        it helps you to
                                                        bypass common issues with registrations.
                                                    </p>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={'col-xs-12'}>
                                    <div className={'bottom-seo-text'}>
                                        <h2>The tech behind disposable email
                                            addresses</h2>
                                        <p>Everyone owns an email address each and every hour, for everything from
                                            connecting at work, with business prospects, reaching out to friends and
                                            colleagues using the email address as an online passport. Nearly 99% of all
                                            apps and services we sign-up today required an email address, likewise to
                                            most shoppers loyalty cards, contest and offer entries, and more.<br/><br/>We
                                            all enjoy having an email address, but getting tons of spam emails each day
                                            doesn’t feel comfortable. Furthermore, it’s entirely common for stores to
                                            have their databases hacked, leaving your business email address at risk and
                                            more likely to end up on spam lists. Still, nothing done online is 100%
                                            private. Thus you need to protect your email contact identity and best done
                                            using disposable emails address.</p>
                                        <h2>So, What Is A Disposable Email Address?</h2>
                                        <p>Recently, I found a bounce rate complex than usual on my latest email blast!
                                            I later realized the surge of users (or bots) signing up for my services
                                            hiding their real identity using disposable mail addresses.</p>
                                        <p>Disposable email address (DEA) technically means an approach where a user’s
                                            with a unique email address gets a temporary email address for your current
                                            contact. The DEA allow the creation of an email address that passes validity
                                            need to sign-up for services and website without having to show your true
                                            identity.<br/><br/>Disposable emails address if compromised or used in
                                            connection with email abuse online, the owner can’t be tied to the abuse and
                                            quickly cancel its application without affecting other contacts. With
                                            temporary mail, you can you receive your emails from the fake emails in your
                                            genuine emails address for a specified time set. The fake email address is
                                            simply a through-away email, temporary email set and self-destructs
                                            email.<br/><br/></p>

                                        <h2>Why would you need a fake email address?</h2>
                                        <ul>
                                            <li><strong>Sign-Up For Store Loyalty Card:</strong><br/>If you don’t want
                                                to get promotional emails from the store adverting new products, use a
                                                disposable email address instead of your business email address, and you
                                                rule out spam emails. If the store gets hacked for email, you real email
                                                address won’t get stolen.
                                            </li>
                                            <li><strong>Test Your App:</strong><br/>You just completed coding a web app,
                                                and you want to test it comprehensively before releasing it for sale,
                                                you can easily get 100 disposable emails, create dummy accounts and test
                                                it yourself other than hiring unreliable users online to test the app.
                                            </li>
                                            <li><strong>Sign-Up For Double Account With A Web App:</strong><br/>You need
                                                another IFTTT account to program a second Twitter account run for your
                                                marketing site. A new account needs a different mail from your default,
                                                to rule out managing a new email inbox, get a new disposable email
                                                address at temp-mail.org
                                            </li>
                                            <li><strong>Eliminate Spam:</strong><br/>A Disposable email address is a
                                                very useful tool against spam, especially, for users who consistently
                                                access web forms, forums and discussion groups you can curb spam to an
                                                absolute minimum with a disposable email address.
                                            </li>
                                        </ul>
                                        <h2>How to Choose a Disposable Email?</h2>
                                        <p>The best fake email provider should:</p>
                                        <ul>
                                            <li>Allow users create temporary emails address at the click of a button.
                                            </li>
                                            <li>No registration is registration or identity information about the
                                                user.
                                            </li>
                                            <li>The email address should remain anonymous.</li>
                                            <li>Offer more than one email address (as many as you may want).</li>
                                            <li>Offers temporarily email stored (temporal email inbox at user’s
                                                disposal).
                                            </li>
                                            <li>Straightforward and functional design to get a mundane email.</li>
                                            <li>Provider random account and users can choose an address of choice.</li>
                                        </ul>
                                        <p>Thus stay spam free and save time with temp-mail.org your favorite email
                                            service.</p>
                                        <h2>How to Use Disposable Email Address?</h2>
                                        <p>Users choose to get disposable email address by creating a new email account
                                            with their current email provider’s such as Gmail, but the account comes
                                            with many challenges such as you will have to manage emails new account.
                                            Users, who opt for free mail services by creating a new account, put up with
                                            a new email address.<br/><br/>It’d work if you had one email address and a
                                            few disposable emails from temp-mail.org and managed one account inbox.<br/><br/>The
                                            amazing thing about a disposable email address is you can forward directly
                                            to your real email account. In case the disposable email address is
                                            compromised, and you are suspicious of one of your contacts you can have
                                            those emails sent directly to your trash, and for those necessary
                                            connections have them sent directly to your real email address inbox.</p>
                                        <h2>To Conclude:</h2>
                                        <p>Have a disposable mail address system set up in a fantastic way to make sure
                                            when you participate in online wikis, chat rooms, and file sharing services
                                            and bulletin boards forums your real identity is never disclosed and never
                                            sold to anyone to avoid mail spam with Temp-mail.org.</p>
                                        {/*<!-- after bottom-text-seo -->*/}
                                        <div className={"popular-articles-ad"}>

                                            <div>
                                                 will contain ads
                                            </div>
                                        </div>
                                        {/*<!--End after bottom-text-seo -->*/}

                                    </div>

                                </div>
                            </div>
                        </div>

                    </main>

                    {/*
                       <section className={'random_email_section'}>
                        <p className={'random_mailbox_generation_notice'}><span>Get a random email address: </span></p>

                        <div className={'random_email_div'}>
                            <div>
                                <form onSubmit={getRandomEmail}>
                                    <button className={'generate_random_mailbox_btn'} type={'submit'}
                                            disabled={isRandomButtonDisabled}>
                                        {isRandomButtonDisabled ? 'Generating...' : 'Generate random email'}</button>
                                </form>

                            </div>

                            // the bellow line won't be shown until the random email is generated*
                            <div className={'random_email_confirmation_div'}>
                                <span>Generated Email: <span id={'random_mailbox_holder'}></span></span>
                                <button type={'button'} className={'main_proceed_btn'}
                                        onClick={randomMailboxProceed}>Proceed
                                </button>
                            </div>

                        </div>
                    </section>
                    <section className={'custom_email_section'}>
                        <div className={'custom_email_div'}>
                            <div className={'custom_email_creation_notice'}>Or create your custom email address:</div>
                            <div>
                                <form onSubmit={customMailBoxProceed}>
                                    <span>Specify your custom username: </span>
                                    <input type={'text'} maxLength={12} minLength={6} id={'custom_username'} required={true}
                                    placeholder={'no special characters'}/>
                                    <span>&nbsp; Select the desired domain: </span>
                                    <select required={true} id={'domains_select'}>

                                    </select>
                                    <button type={'submit'} className={'main_proceed_btn'}>Proceed</button>
                                </form>
                            </div>


                        </div>
                    </section>


                    <div className={'selected_email_confirmations_div'}>

                        The Selected email address is: <span id={'selected_mailbox_confirmation'}></span>
                        <button type={'button'} onClick={getMailboxEmails} className={'check_mail_btn'}
                        disabled={isGettingEmailsDisabled}>{ isGettingEmailsDisabled ?
                            'Checking inbox...' : 'Check inbox'}</button>
                    </div>

                    <section className={'emails_holder_section'}>
                        <div id={'emails_holder_div_id'} className={'emails_holder_div'}>
                            <div className={'no_emails_div'}>
                                <img alt={'image to indicate emptiness'} className={'no_emails_img'} draggable={false}
                                     src={EmptyInboxImg}/>
                            </div>
                        </div>


                    </section>

                */}

                </div>



            </div>


          {<Footer/>}

      </>
  );
}

export default Home;
