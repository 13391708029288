import '../App.css';
import NavbarMain from "../Components/Navbar";
import Footer from "../Components/Footer";


function Blog() {
    return (
        <>
            <NavbarMain />
            <div className="Blog">
                <p style={{'margin-top': '50px'}}>Hey there, this is the blog page.</p>
            </div>
            <Footer />
        </>

    );
}

export default Blog;
