import React from "react";
import NavbarMain from "../../Components/Navbar";
import Footer from "../../Components/Footer";
export default function TempMailUseCasesArticle(){
    return(
        <>
            <NavbarMain />
            <div style={{'margin-top': '50px'}}>
                <p>Hey There.</p>
            </div>


            <Footer/>
        </>
    )
}