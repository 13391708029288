import React from 'react';
import '../App.css';

export default function Footer() {
    let current_date = new Date().getFullYear();

    return(
        <>
            <div className={'footer_main_div'}>
                <p className={'footer_copy'}>&copy; {current_date}</p>
                <div>
                    <button className={'footer-common-btn'}>Site map</button>
                    <button className={'footer-common-btn'}>System status</button>
                    <button className={'footer-common-btn'}>Privacy Policy</button>
                    <button className={'footer-common-btn'}>About Premium</button>
                    <button className={'footer-common-btn'}>Terms</button>
                    <button className={'footer-common-btn'}>FAC</button>
                    <button className={'footer-common-btn'}>Contact</button>
                    <button className={'footer-common-btn'}>Advertising</button>
                </div>

            </div>
        </>
    )
}