import React from 'react';
import '../App.css';

import {Link, useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";
import DisposableEmailLogo from '../Assets/test_logo.png'

export default function NavbarMain() {
    let navigate = useNavigate();

    return (
        <>
            <header className={'header-logged-out'}>
                <a className={'gl-sr-only gl-accessibility'}></a>
                <div className={'container-fluid'}>
                    <nav className={'header-logged-out-nav'} aria-label={'Explore Tempmail'}>
                        <div className={'nav-logged-out-logo'}>
                            <span className={'span-logo-header'}>TempMail</span>
                            <a id={'logo'} className={'header-logged-out-logo'} aria-label={'Homepage'}
                               title={'Homepage'} href={'/'}>
                                <img draggable={false} className={'brand-header-logo'} alt={'logo'}
                                     src={DisposableEmailLogo}/>
                            </a>
                            {/*<ul className={'header-ul-item-first'}>
                                <li className={'header-logged-out-nav-item'}>
                                    <button className={'header-btn-premium'}
                                            onClick={() => {
                                                navigate('/premium')
                                            }}>Go Premium
                                    </button>

                                </li>
                            </ul>*/ }
                        </div>

                        <ul className={'header-ul-item-second'}>
                            <li className={'header-logged-out-nav-item'}>
                                <Link to={'/'}>Home</Link>
                                <Link to={'/blog'}>Blog</Link>
                                <Link to={'/about'}>About Us</Link>
                                <button className={'header-btn-signin'}
                                        onClick={() => {
                                            navigate('/login')
                                        }}>Sign in
                                </button>
                                <button className={'header-btn-premium'}
                                        onClick={() => {
                                            navigate('/premium')
                                        }}>Go Premium
                                </button>
                            </li>
                            {/*<li className={'header-logged-out-nav-item'}>
                                <button className={'header-btn-premium'}
                                        onClick={() => {
                                            navigate('/premium')
                                        }}>Go Premium
                                </button>

                            </li>*/}
                        </ul>

                        { /*<div>
                            <button onClick={() => {
                                navigate('/') }}>Home</button>
                            <button onClick={ () => { navigate('/blog') }}>Blog</button>
                            <button onClick={ () => { navigate('/about') }}>About Us</button>
                            <button onClick={ () => { navigate('/login') }}>Login</button>
                            <button onClick={ () => { navigate('/premium') }}>Premium</button>
                        </div> */}
                    </nav>
                </div>
            </header>
        </>
    )
}