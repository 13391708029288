import axios from 'axios';

import NavbarMain from "../Components/Navbar";
import Footer from "../Components/Footer"
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import LoadingImage from '../Assets/reading_email_1.gif';

export default function EmailExplorer(){
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const api_endpoint = 'http://localhost:5000/api/v1/'
    let url_email_id = searchParams.get('email_id');
    let url_login = searchParams.get('login');
    let url_domain = searchParams.get('domain');


    let request_obj = {
        sel_login: url_login,
        sel_domain: url_domain,
        mail_id: url_email_id
    }

    const goHome = () => {
        navigate('/');
    }

    const fetchMailData = async () => {
        try{
            const email_explorer_main_div = document.getElementById('email_explorer_holder_div_id');
            email_explorer_main_div.innerHTML = `<div class='email_in_progress_img_holder'>
                                    <img alt={'reading email in progress'} class='reading_email_img'
                                         src=${LoadingImage}/>
                                </div>`;
            const response = await axios.post(api_endpoint.concat('read_email'), request_obj)
            const data = response.data['message_full_data'];
            if (response.data['res_status'] === 200){
                let msg_sender = data.from;
                let msg_subject = data.subject;
                let msg_date = data.date;
                let msg_body = data.body;
                email_explorer_main_div.innerHTML = ``;

                let email_header = document.createElement('header');
                email_header.setAttribute('class', 'email_read_header');
                email_header.innerHTML = `<div>
                                            <p><span class="msg_header_span">Sender :</span> <span>${msg_sender}</span></p>
                                            <p><span class="msg_header_span">Subject :</span> <span>${msg_subject}</span></p>
                                        </div>
                                        <div><span class="msg_header_span">Date :</span> <span>${msg_date}</span></div>`;
                // appending the above to the main holder
                email_explorer_main_div.appendChild(email_header);
                let email_body_div = document.createElement('div');
                email_body_div.setAttribute('class', 'email_body_html');
                email_body_div.innerHTML = `<div class='email_body_holder'>
                                            <span>${msg_body}</span>
                                        </div>`;
                email_explorer_main_div.appendChild(email_body_div);

                if (response.data['attachment_links'].length !== 0) {
                    let attachments_sep_div = document.createElement('div');
                    attachments_sep_div.setAttribute('class', 'bottom_attachments_border_holder');
                    attachments_sep_div.innerHTML = `<div class='bottom_attachments_border_div'></div>`;
                    email_explorer_main_div.appendChild(attachments_sep_div);
                    let attachments_holder_div = document.createElement('div');
                    attachments_holder_div.setAttribute('class', 'appended_attachments_div');
                    let i = 0;
                    while ( i < response.data['attachment_links'].length){
                        //creating and adding attachments divs to the holder div
                        let attachment_elem_div = document.createElement('div');
                        attachment_elem_div.setAttribute('class', 'attachments_elem_holder')
                        attachment_elem_div.innerHTML = `
                        <a class="attachments_elem_link" href="${response.data['attachment_links'][i]}">get ${data.attachments[i].filename}</a>
                        `;
                        attachments_holder_div.appendChild(attachment_elem_div);
                         i = i + 1;
                    }
                    //appending the holder div to the main div
                    email_explorer_main_div.appendChild(attachments_holder_div);
                }

            /*    email_explorer_main_div.innerHTML = `<div>
                                    <header class='email_read_header'>
                                        <div>
                                            <p>Sender: </p>
                                            <p>Subject: </p>
                                        </div>
                                        <div><span>Date: </span></div>
                                    </header>
                                    <div class='email_body_html'>
                                        <div class='email_body_holder'>
                                            <span>email body content goes here</span>
                                        </div>

                                    </div>

                                    <div class='bottom_attachments_border_holder'>
                                        <div class='bottom_attachments_border_div'></div>
                                    </div>

                                    <div class='email_attachments_holder'>
                                            <div class='email_attachments_inner'>

                                            </div>
                                    </div>
                                </div>`; */

            }

            // when populating data, need to check for the attachments links in the data object.
        } catch (error) {
            alert('error occurred. try again, more details: ' + error);
        } finally {

        }
    }
    useEffect(() => {
        fetchMailData().then(r => {})
    }, []);

    return (
        <>
            {<NavbarMain/>}
            <div className={'root_div'}>
                <div className={'main_container'}>
                    <div className={'container_main_holding'}>
                        <section className={'back_home_section'}>
                            <p className={'read_email_notice_parag'}>In here you can read the content of your email and download it's attachments
                                if any. <span className={'respond_func_notice'}>But you cant respond to emails, Try premium to access the respond functionality</span></p>
                            <button onClick={goHome} className={'go_back_btn'} type={'button'}>Go back</button>
                        </section>

                        <section className={' email_explorer_holder_section'}>
                            <div className={'email_explorer_holder_div'} id={'email_explorer_holder_div_id'}>

                                {/* <div id={'email_explorer_inner_id'}>
                                    <header className={'email_read_header'}>
                                        <div>
                                            <p>Sender: </p>
                                            <p>Subject: </p>
                                        </div>
                                        <div><span>Date: </span></div>
                                    </header>
                                    <div className={'email_body_html'}>
                                        <div className={'email_body_holder'}>
                                            <span>email body content goes here</span>
                                        </div>

                                    </div>

                                    <div className={'bottom_attachments_border_holder'}>
                                        <div className={'bottom_attachments_border_div'}></div>
                                    </div>

                                    <div className={'email_attachments_holder'}>
                                            <div className={'email_attachments_inner'}>

                                            </div>
                                    </div>
                                </div> */}

                            </div>
                        </section>


                    </div>

                    <div className={'main_side_screen_ad_place_right_1'}>
                        <div className={'right_side_ad_div_holder'}>
                            <p>Advertisement</p>
                        </div>
                        <div className={'right_side_ad_div_holder'}>
                            <p>Advertisement</p>
                        </div>
                    </div>

                </div>
                <div className={'main_bottom_screen_ad_place_down_1'}>
                    <p>Advertisement</p>
                </div>

            </div>
            {<Footer />}
        </>

    )
}