import '../App.css';
import NavbarMain from "../Components/Navbar";
import Footer from "../Components/Footer";

function Login() {
    return (
        <>
            <NavbarMain/>
            <div className="Login">
                <p>Hey there, this is the Login page.</p>
            </div>
            <Footer />
        </>

    );
}

export default Login;
