import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import Home from './Pages/App';
import About from './Pages/About';
import Login from "./Pages/Login";
import Premium from "./Pages/Premium";
import Register from "./Pages/Register";
import Blog from './Pages/Blog';
import EmailExplorer from "./Pages/Email_explorer";
import TempMailUseCasesArticle from "./Objects/Articles/TempMailUseCases";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route index path='/' element={<Home />} />
              <Route path='/blog' element={<Blog/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/login' element={<Login/>} />
              <Route path='/register' element={<Register/>} />
              <Route path='/premium' element={<Premium/>} />
              <Route path='/email_explorer' element={<EmailExplorer />} />
              <Route path='/blog/temp-mail-use-cases' element={<TempMailUseCasesArticle/>} />
          </Routes>
      </BrowserRouter>

  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
