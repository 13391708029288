import '../App.css';
import NavbarMain from "../Components/Navbar";
import Footer from "../Components/Footer";

function Premium() {
    return (
        <>
            <NavbarMain/>
            <div className="Premium">
                <p>Hey there, this is the Premium page.</p>
            </div>
            <Footer />
        </>

    );
}

export default Premium;
