import '../App.css';
import NavbarMain from "../Components/Navbar";
import Footer from "../Components/Footer";

function About() {
    return (
        <>
            <NavbarMain/>
            <div className="About">
                <p>Hey there, this is about page.</p>
            </div>
            <Footer />
        </>

    );
}

export default About;
